<template>
  <ion-page>
    <div class="background--login" />
    <ion-content
      :scroll-y="false"
      class="ion-padding login-top-text"
    >
      <div class="d-flex flex-column h-100 max-width">
        <HeaderTwo />
        <div
          v-if="!passwordReset"
          class="before-password-reset"
        >
          <div class="resetpassword">
            <ion-text class="text--gray">
              {{ $t('resetPasswordDescriptionText') }}
            </ion-text>
            <form
              class="login-form form--dark flex-1"
              :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
            >
              <ion-item lines="none">
                <ion-label position="floating">
                  {{ $t('emailAddress') }}
                </ion-label>
                <ion-input
                  id="username"
                  v-model="email"
                  enterkeyhint="done"
                  autocomplete="email"
                  type="email"
                  required
                />
              </ion-item>
              <ion-button
                expand="block"
                :disabled="!email"
                style="margin-bottom: 25px"
                @click="resetMyPassword"
              >
                <ion-spinner
                  v-if="loading"
                  name="crescent"
                />
                <ion-label v-else>
                  {{ $t('Reset Password') }}
                </ion-label>
              </ion-button>
            </form>
          </div>
          <div
            class="dont-have"
          >
            <ion-row>
              <ion-col>
                <ion-text class="text--gray">
                  {{ $t('Dont have an account?') }}
                </ion-text>
                <router-link
                  to="/signup"
                  class="signin-link"
                >
                  {{ $t('Sign Up') }}
                </router-link>
              </ion-col>
            </ion-row>
          </div>
        </div>
        <div
          v-if="passwordReset"
          class="after-password-reset"
        >
          <img src="../assets/images/reset-icon.png">
          <ion-text class="text--white">
            {{ $t('resetPasswordInstructionsSent') }}
          </ion-text>
          <router-link
            to="/login"
            class="signin-link"
          >
            {{ $t('Back to Login') }}
          </router-link>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { alertController } from '@ionic/vue'
import { useRouter } from 'vue-router'

import google from '@/assets/images/google.png'
import HeaderTwo from '../components/HeaderTwo.vue'

export default {
  components: {
    HeaderTwo
  },
  data () {
    const router = useRouter()
    const leftIcon = '../../img/icons/back-arrow.png'
    return {
      router,
      email: '',
      loading: false,
      passwordReset: false,
      leftIcon,
      google
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  methods: {
    async presentAlert (message) {
      const alert = await alertController
        .create({
          header: message,
          subHeader: '',
          message: '',
          buttons: [this.$t('Dismiss')]
        })
      await alert.present()

      const { role } = await alert.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    },
    resetMyPassword () {
      this.loading = true
      const email = this.email
      this.$store.dispatch('resetMyPassword', { email }).then(response => {
        this.passwordReset = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/theme/_colors";
@import "~@/theme/_backgrounds";

ion-content {
  .welcome-text {
    margin-top: 80px;
    font-size: 35px;
  }
  .resetpassword{
      margin-top:20vh;
  }
  p {
    color: rgba( 217, 255, 217, 0.2);
  }
  .dont-have{
      position: absolute;
      bottom: 50px;
      text-align: center;
      left:0;
      right:0;
  }
  .after-password-reset{
      margin-top:20vh;
      ion-text{
          margin-top:30px !important;
          margin-bottom: 30px !important;
          display: block;
      }
      .signin-link{
          font-weight: 400;
          letter-spacing: 0.4px;
      }
  }
  ion-button {
    height: 50px;
    text-transform: none;
  }

  ion-button.google-signin {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    @include background-item--dark-transparent;
    --box-shadow: none;
    font-size: 18px;
    font-weight: bold;
    padding: none;
    margin: 30px 0;

    ion-icon {
      margin-right: 10px;
    }
  }
}

.login-form {
  margin-top: 30px;
}

.forgot-password {
  margin-top: 3vh;
  color: colors(primary, base);
}

.sign-up-link {
  margin-left: 5px;
  color: colors(primary, base);
}
hr {
  border-top: 2px solid rgba(255, 212, 164, 0.12);
  width: 100%;
}
.login-top-text{
  letter-spacing: 0.4px;
  ion-text{
    font-weight: 300;
  }
  h1{
    margin-top:10vh;
  }
  .google-signin{
      label{
        font-size: 17px;
      }
  }
}

@media only screen and (min-width: 1000px) {
  .max-width {
    margin-left: 50vh;
    margin-right: 50vh;
  }
}
</style>
