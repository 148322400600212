<template>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons
        slot="start"
        class="profile-image"
        @click="$emit('onBackClick')"
      >
        <router-link
          to="/signup"
          class="signin-link"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </router-link>
      </ion-buttons>
      <ion-title>{{ $t('Reset Password') }}</ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: false,
      default: ''
    },
    pageName: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['onBackClick']
}
</script>

<style lang="scss" scoped>
ion-header, ion-toolbar, ion-content {
  --background: none;
  color: #fff;
  --box-shadow: none;
}

.page-name-header {
  text-align: center;
}
.signin-link{
  img{
    width:24px;
  }
}
</style>
